import React from "react";

const FaqQuestion = ({ question, answer }) => {

  const collapse = (event) => {
    console.log(event)
    console.log(event.target.nextElementSibling)

    const icon = event.target.lastElementChild;
    console.log('Icon: ', icon.innerHTML)
    icon.innerHTML === '+' ? icon.innerHTML = '-' : icon.innerHTML = '+';

    const faqAnswer = event.target.nextElementSibling;
    if (faqAnswer.style.maxHeight) {
      faqAnswer.style.maxHeight = null;
    } else {
      faqAnswer.style.maxHeight = faqAnswer.scrollHeight + "px";
    }
  }

  return (
    <>
      <h3 className="faq-question" onClick={collapse}><span class="icon">+</span> {question}</h3>
      <p className="faq-answer">{answer}</p>
    </>
  )
}

export default FaqQuestion;
