import React from "react"
import Layout from "../components/Layout"
import Pagetitle from "../components/Pagetitle"
import FaqQuestion from "../components/FaqQuestion"
import Seo from "../components/Seo"

function FAQPage() {
  const collapse = (event) => {
    console.log(event)
    console.log(event.target.nextElementSibling)

    const icon = event.target.lastElementChild
    console.log("Icon: ", icon.innerHTML)
    icon.innerHTML === "+" ? (icon.innerHTML = "-") : (icon.innerHTML = "+")

    const faqAnswer = event.target.nextElementSibling
    if (faqAnswer.style.maxHeight) {
      faqAnswer.style.maxHeight = null
    } else {
      faqAnswer.style.maxHeight = faqAnswer.scrollHeight + "px"
    }
  }

  const faq = {
    General: {
      1: [
        "How can I check my product feed?",
        "Simply paste the feed url in a new browser window, the feed should automatically download in CSV format. You can then open the file with Excel or Google Sheets to analyze the contents.",
      ],
      2: [
        "How frequently does my feed update?",
        "Feeds update everyday between 1-3AM EST, make sure you set the daily feed update time in Facebook/Pinterest at least a few hours later (5am) to make sure your feed is fully synced every day.",
      ],
      3: [
        "How do I exclude certain products or categories?",
        "The best way to exclude products is to set up exclusions in the platform. In Pinterest you can create product groups and only include the products in the groups you want to show on your profile page or advertise with. In Facebook (and Instagram) you can do the same thing using product sets. Google Merchant Center provides Feed Rules to exclude or modify products.",
      ],
      4: [
        "My feed doesn't contain product variants",
        "If you want all product variants included in your feed, make sure the checkbox 'Include product variants' under settings on the App Homepage, is checked. If the settings are correct, click the Generate Feed button to generate a updated feed with all your store's product. If there are still products missing please contact us",
      ],
      5: [
        "My feed doesn't contain all my products",
        "By default the app only includes products and variants that are marked as available in your online store. If there are still products missing please contact us.",
      ],
      6: [
        "How do I add my feed to Pinterest, Facebook, Instagram or Google?",
        "See our Getting Started page for step-by-step platform specific instructions",
      ],
      7: [
        "Is there a field for additional product information?",
        "You can add specific tags to your products in Shopify, these tags will show up in the feed in the designated field custom_label_0. You use tags to create certain product sets/groups that contain products from multiple brands or categories.",
      ],
      8: [
        "Does the feed include sale pricing?",
        "Yes by default sales prices are included in your feed",
      ],
    },
    Pinterest: {
      1: ["How do I add my feed to Pinterest?", ""],
      2: ["My products are not showing up after setting up my feed", ""],
      3: ["How do I exclude certain products from Pinterest?", ""],
    },
    Facebook: {
      1: ["How do I add my feed to Facebook?", ""],
      2: ["Can I use the feed to set up a Facebook shop?", ""],
      3: ["Can I use the feed to run ads on Facebook?", ""],
    },
    Instagram: {
      1: ["How do I add my feed to Instagram?", ""],
      2: ["How do I create a Shoppable post?", ""],
      3: ["Can I use the feed to run ads on Instagram?", ""],
    },
    Google: {
      1: ["How do I add my feed to Google?", ""],
      2: ["How do I feature products on the Google Shopping tab?", ""],
      3: ["Can I use the feed to advertise products with Google Ads?", ""],
    },
  }

  return (
    <Layout>
      <Seo
        title="Frequently Asked Questions"
        description="Upfeed frequently asked questions"
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <Pagetitle>FAQ</Pagetitle>

            {/* <h2>App</h2> */}
            {Object.keys(faq.General).map((key) => (
              <FaqQuestion
                question={faq["General"][key][0]}
                answer={faq["General"][key][1]}
              />
            ))}

            {/* <h2>Pinterest</h2>
          {Object.keys(faq.Pinterest).map(key => <FaqQuestion question={faq['Pinterest'][key][0]} answer={faq['Pinterest'][key][1]} />)}

          <h2>Facebook</h2>
          {Object.keys(faq.Facebook).map(key => <FaqQuestion question={faq['Facebook'][key][0]} answer={faq['Facebook'][key][1]} />)}

          <h2>Instagram</h2>
          {Object.keys(faq.Instagram).map(key => <FaqQuestion question={faq['Instagram'][key][0]} answer={faq['Instagram'][key][1]} />)}

          <h2>Google</h2>
          {Object.keys(faq.Google).map(key => <FaqQuestion question={faq['Google'][key][0]} answer={faq['Google'][key][1]} />)} */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FAQPage
